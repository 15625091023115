<template>
  <v-form>
    <h3 class="pt-6 pb-4">Report Sheet</h3>

    <p>Enter the number of your dye below:</p>
    <calculation-input v-model="inputs.dyeID" prepend-text="Dye Number :" />
    <!-- Data Upload -->
    <h3 class="pt-8 pb-3">Spectrum Upload</h3>

    <p class="mb-2">Upload the graph of your full spectrum below.</p>

    <p class="mb-5">
      <file-upload-ui-component
        style="width: 100%"
        label="Click here to select a file"
        :max-number-of-files="1"
        :max-file-size="20"
        accepted-file-extensions=".png, .jpg ,.jpeg"
        @upload:attachments="attachments = $event"
      />
    </p>

    <p>
      <stemble-latex
        content="Enter your observed value of $\lambda_{\text{max}}~(\text{nm}$) below:"
      />
    </p>
    <calculation-input
      v-model="inputs.lambdaMax"
      prepend-text="$\lambda_{\text{max}}~:$"
      append-text="$\text{nm}$"
    />
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default {
  name: 'BeerPostLabQ1',
  components: {FileUploadUiComponent, StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],

  data() {
    return {
      inputs: {
        dyeID: null,
        lambdaMax: null,
      },
      attachments: [],
    };
  },
};
</script>
